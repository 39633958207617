.section--TextImageShapeSection::after {
  content: '';
  display: block;
  clear: both;
}

.section {
  &--TextImageShapeSection {
    &.section--dark .container * {
      color: var(--ac-text-light-color);
    }
  }
}

.image-shape {
  overflow: hidden;
  position: relative;

  .c-dropzone .dropzone-icons {
    position: relative !important;
    right: unset !important;
    margin-bottom: 10px;
  }

  &.left {
    z-index: 1;
    float: left;
    height: 500px;
    width: 500px;
    margin-right: 20px;
    .c-dropzone {
      min-height: 500px !important;
    }
  }

  &.right {
    z-index: 1;
    float: right;
    height: 300px;
    width: 300px;
    margin-left: 20px;
    .c-dropzone {
      min-height: 300px !important;
    }
  }

  &--diamond {
    shape-outside: polygon(0 50%, 50% 100%, 100% 50%, 50% 0);
    clip-path: polygon(0 50%, 50% 100%, 100% 50%, 50% 0);
  }

  &--circle {
    border-radius: 50%;
    margin: 20px;
    shape-outside: circle();
    &.left {
      .c-dropzone {
        &-overlay__lines {
          border-radius: 50%;
        }
      }
    }
    &.right {
      .c-dropzone {
        &-overlay__lines {
          border-radius: 50%;
        }
      }
    }
  }

  &--diagonal {
    &.left {
      shape-outside: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%);
    }
    &.right {
      shape-outside: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
    }
  }

  &--ellipse {
    &.left {
      clip-path: ellipse(90% 70% at 0% 50%);
      shape-outside: ellipse(90% 70% at 0% 50%);
    }
    &.right {
      clip-path: ellipse(70% 90% at 70% 0%);
      shape-outside: ellipse(70% 90% at 70% 0%);
    }
  }

  img,
  .c-dropzone {
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &--text {
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .image-shape {
    &.right {
      float: none !important;
    }
    &--ellipse,
    &--diagonal {
      &.left,
      &.right {
        z-index: 1;
        shape-outside: none;
        clip-path: none;
        width: 100%;
        height: 300px;
        margin: 10px 0px !important;
        .c-dropzone {
          min-height: 300px !important;
        }
      }
    }
  }
}
