@import './styles/_template';
@import './devices.css';

body {
  background-color: #fafafa;
  overflow-x: hidden;
}

.content h2 {
  margin-bottom: 0px;
}

.menu.is-fixed-left {
  .navbar-item--sign-out {
    background: transparent;
    border: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition: border-bottom-color 250ms ease-in-out;
  background-color: var(--ac-color-primary);

  .navbar-item--sign-out {
    background: transparent;
    border: 0;
    cursor: pointer;
    margin-left: 1em;

    &:hover {
      opacity: 0.8;
    }
  }

  &.invert,
  &:not(.invert) {
    .navbar-menu a.navbar-item {
      &--classic {
        &:hover,
        &.is-active {
          background: var(--ac-color-contrast-20);
        }
      }
      &--tab {
        border-color: transparent;
        border-bottom-width: 2px;
        border-bottom-style: solid;
      }
      &--button {
        border-radius: 4px;
        margin-left: 4px;
        margin-bottom: 4px;
        &:hover {
          opacity: 0.7;
        }
      }
      &--bordered,
      &--bordered-rounded {
        background-color: transparent !important;
        &.is-active {
          border: 1px solid transparent !important;
        }
      }
    }
  }

  &.invert {
    .navbar-menu {
      .navbar-item--sign-out {
        color: var(--ac-color-primary-contrast);
      }

      a.navbar-item {
        &.is-active {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &--classic {
          & span {
            color: var(--ac-color-primary-contrast);
          }
        }

        &--tab {
          & span {
            color: var(--ac-color-primary-contrast);
          }
          &:hover,
          &.is-active {
            border-bottom-color: var(--ac-color-primary-contrast);
          }
        }

        &--button {
          background-color: var(--ac-color-primary);
          span {
            color: var(--ac-color-contrast);
          }
          &.is-active {
            span {
              color: var(--ac-color-primary-contrast);
            }
          }
        }
        &--bordered,
        &--bordered-rounded {
          border: 1px solid var(--ac-color-primary) !important;
          span {
            color: var(--ac-color-primary-contrast) !important;
          }
        }
      }
    }
  }

  &:not(.invert) {
    .menuTitle strong {
      color: var(--ac-color-contrast) !important;
    }
    .navbar-menu {
      background-color: transparent;
      .navbar-item--tab.navbar-item--languages {
        border-bottom: 2px solid transparent;
      }

      .navbar-item--sign-out {
        color: var(--ac-color-contrast);
      }

      a.navbar-item {
        &--classic {
          & span {
            color: var(--ac-color-contrast);
          }
        }

        &--tab {
          & span {
            color: var(--ac-color-contrast);
          }
          &:hover,
          &.is-active {
            background: transparent;
            border-bottom-color: var(--ac-color-contrast);
          }
        }

        &--button {
          background-color: white;
          span {
            color: var(--ac-color-primary-contrast);
          }
          &.is-active {
            background-color: transparent;
            span {
              color: var(--ac-color-contrast);
            }
          }
        }
        &--bordered,
        &--bordered-rounded {
          border: 1px solid var(--ac-color-contrast) !important;
          span {
            color: var(--ac-color-contrast) !important;
          }
        }
      }
    }
  }

  .menuTitle p {
    color: var(--ac-color-contrast) !important;
  }
}

.navbar-item {
  &--primary,
  &--bordered {
    margin-right: 4px;
  }
  &--primary-rounded,
  &--bordered-rounded {
    border-radius: 300px !important;
  }
}

.button-type {
  &--primary,
  &--primary-rounded {
    background-color: var(--ac-color-primary) !important;
    color: white !important;
    border-radius: 5px;
    margin-right: 4px;
    font-size: 15px;
    padding: 8px 12px;
    & span {
      color: white !important;
    }
  }
  &--bordered,
  &--bordered-rounded {
    background-color: transparent !important;
    color: var(--ac-color-primary) !important;
    border: 1px solid var(--ac-color-primary) !important;
    border-radius: 5px;
    margin-right: 4px;

    font-size: 15px;
    padding: 8px 12px;

    & span {
      color: var(--ac-color-primary) !important;
    }
  }
  &--primary-rounded,
  &--bordered-rounded {
    border-radius: 300px !important;
  }
}

.translucent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  opacity: 0.1;
}

.buttons-row-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.image {
  &--gallery {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 0;
    cursor: pointer;
    padding-top: 100%;
  }

  &--16_9 {
    padding-top: 56.25%;
  }

  &--4_3 {
    padding-top: 75%;
  }

  &--square {
    padding-top: 100%;
  }
}

.hero-body .image {
  margin: 0 auto;
}

.hero-body.hero-body--absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section--HeroImage > .hero-body {
  padding: 0;
}

.section.section--MultiImageHeaderSection {
  padding: 0;
}

/************* Section Color System ************/

.section {
  --ac-text-color: #4a4a4a;
  --ac-surface-color: var(--ac-color-primary);
  --ac-text-light-color: #fff;
  --ac-text-primary-color: var(--ac-color-primary);
  --ac-surface-light-color: #fff;

  --ac-countdown-bg-color: var(--ac-surface-color);
  --ac-countdown-color: var(--ac-text-light-color);

  --ac-icon-color: var(--ac-text-primary-color);
  --ac-icon-accent-color: var(--ac-text-light-color);
  --ac-icon-bg-color: var(--ac-surface-color);

  --ac-program-bg-color: var(--ac-surface-color);
  --ac-program-content-color: var(--ac-text-light-color);

  --ac-timeline-arrow-color: var(--ac-surface-color);
  --ac-timeline-wrapper-color: var(--ac-surface-color);
  --ac-timeline-color: var(--ac-text-light-color);

  color: var(--ac-text-color);

  &--dark {
    --ac-countdown-bg-color: var(--ac-surface-light-color);
    --ac-countdown-color: var(--ac-text-primary-color);

    --ac-icon-color: var(--ac-text-light-color);
    --ac-icon-accent-color: var(--ac-text-primary-color);
    --ac-icon-bg-color: var(--ac-surface-light-color);

    --ac-program-bg-color: var(--ac-surface-light-color);
    --ac-program-content-color: var(--ac-text-primary-color);

    --ac-timeline-arrow-color: var(--ac-surface-light-color);
    --ac-timeline-wrapper-color: var(--ac-surface-light-color);
    --ac-timeline-color: var(--ac-text-primary-color);

    .title,
    .subtitle,
    .item--text,
    .field-label > .label {
      color: var(--ac-text-light-color);
    }

    color: var(--ac-text-light-color);

    .background-light,
    .card {
      color: var(--ac-text-color);
      .title,
      .subtitle,
      .content {
        color: var(--ac-text-color);
      }
      &.card--metro,
      &.card--none {
        * {
          color: var(--ac-text-light-color);
        }
      }
    }
  }
}

/********* Speakers *********/

.alphabet {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 8px;
}

.alphabet-letter {
  flex: 1;
  flex-basis: 0;
  padding: 4px;
  border: 1px solid #ddd;
  /* border: 1px solid rgb(38, 183, 138); */
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--ac-color-white-2);
  margin: 1px;
  text-align: center;
  min-width: 32px;
  min-height: 32px;
  font-weight: bold;
  text-transform: uppercase;
}

.alphabet-letter:focus {
  outline: 0;
}

.alphabet-letter:disabled {
  cursor: initial;
  opacity: 0.35;
}

.speaker-grid {
  display: flex;
  flex-wrap: wrap;
}

.container-speaker {
  padding: 8px;
  font-family: 'Poppins', Arial, san-serif;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.ms-speakers__card {
  width: 100%;
  overflow: hidden;
  /* padding: 10px 10px; */
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 3px 10px 1px rgba(60, 60, 60, 0.15);
}

@media (max-width: 767px) {
  .speaker-grid .container-speaker {
    width: 50%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 50%;
  }
}

@media (max-width: 767px) and (max-width: 1023px) {
  .speaker-grid .container-speaker {
    width: 33%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 25%;
  }
}

@media (min-width: 1024px) and (max-width: 1407px) {
  .speaker-grid .container-speaker {
    width: 25%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 16.66%;
  }
}

@media (min-width: 1408px) and (max-width: 1507px) {
  .speaker-grid .container-speaker {
    width: 16.66%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 12.5%;
  }
}

@media (min-width: 1508px) {
  .speaker-grid .container-speaker {
    width: 12.5%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 10%;
  }
}

@media (max-width: 480px) {
  .menuTitle h2 {
    font-size: 15px;
    padding: 5px 10px;
  }

  .navbar {
    .navbar-menu {
      a.navbar-item {
        &--button {
          margin-right: 12px;
          margin-left: 12px;
        }
      }
    }
  }
}

.ms-speaker__card__image {
  width: 100%;
  padding-bottom: 100%;
  display: block;
  background-size: cover;
  background-position: center;
}

.ms-speaker__card__section {
  padding: 8px;
}

.ms-speakers__title {
  font-size: 14px;
  font-weight: 500;
}

.ms-speakers__details {
  font-size: 12px;
  color: rgba(31, 16, 16, 0.65);
}

/********* Services ********/

.ms-services {
  text-align: center;
}

.ms-services h1 {
  font-size: 20px;
  font-weight: bold;
}

.navbar-brand {
  flex-shrink: 1 !important;
  align-items: center !important;

  a {
    flex-shrink: 1 !important;
  }
  .c-richtext {
    margin-left: 8px !important;
    overflow: hidden !important;
  }
}

.navbar-brand .navbar-item {
  padding-top: 0;
  padding-bottom: 0;

  img {
    max-height: 52px;
  }
}

.navbar:not(.is-at-top) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.c-richtext.content {
  margin-bottom: 0;
}

.c-richtext.content {
  p {
    margin-bottom: 0;
  }

  ul {
    margin-top: 0;
  }
}

.c-item {
  &--partner {
    .image {
      padding: 12px;
      width: 100%;
    }

    &--card {
      border: 1px solid #ddd;
      border-radius: 4px;

      .image-container {
        flex-direction: column;
        display: flex;
        justify-content: center;
      }

      .title {
        font-size: 1.25rem;
        padding: 0.2em;
      }
    }
  }
}

.c-searchbar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2em;

  &__label {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .control span.icon.is-left {
    color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .control.has-icons-left > input {
    padding-left: 3.25em;
  }

  .control.has-icons-left > input:focus {
    box-shadow: none;
  }

  .control.has-icons-left .input:focus ~ .icon {
    color: white !important;
  }

  .dropdown-trigger > .button {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    display: block;

    .dropdown {
      width: 100%;
      margin: 10px 0px;
    }

    .dropdown-trigger {
      width: 100%;

      .button {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.dropdown-item.dropdown-header {
  padding-bottom: 0;
}

/* show something for undefined icons ? */

i.fa.fa-undefined:before {
  content: '+';
}

.columns:after {
  content: '';
  display: block;
  clear: both;
}

footer.footer {
  background-color: #333;
  padding: 10px;
}

$arrow-gap: 11px;
$arrow-size: 40px;

.c-arrow--scroll-to-top {
  right: $arrow-gap;
  bottom: $arrow-gap;
  width: $arrow-size;
  height: $arrow-size;
  line-height: $arrow-size;
  z-index: 100;
  border-radius: 50%;
  background-color: #333;
  cursor: pointer;
  text-align: center;
  position: fixed;
}

.semantic_ui_range_inner > div:first-child {
  background-color: #ddd !important;
}

// divider-fields
.df-field {
  display: table;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;

  &__label {
    display: table-cell;
    vertical-align: top;
    width: 50px;
    font-size: 0.75rem;
    color: black;
    font-weight: 700;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__value {
    display: table-cell;
  }
}

.video-container {
  position: relative;
  padding-top: 56.25%;
  /*16:9*/
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 480px) {
  .section--VideoSection {
    padding-left: 0;
    padding-right: 0;
  }
}

// gallery margin
.column-margin {
  &__none {
    padding: 0px;
  }

  &__normal {
    padding: 0.75em;
  }

  &__large {
    padding: 1.5em;
  }
}

.mobile-apps-buttons {
  margin-top: 40px;

  a {
    margin-right: 10px;
  }

  &.top {
    display: flex;
    justify-content: center;
  }
}

.tabs.is-centered.is-fullwidth {
  ul {
    flex-shrink: 1;
  }

  li {
    flex-shrink: 1;
  }
}

.tab--container {
  text-align: center;
}

.list-section {
  &__icon {
    font-size: 30px;
    margin-right: 5px;
  }

  &__social {
    font-size: 30px;
    margin: auto;
    text-align: center;
  }

  &__image {
    margin: auto;
  }
}

/* Responsive bulma tabs */
// @media screen and (max-width: 768px) {
//   li:not(.is-active) > a > span:not(.icon) {
//     visibility: hidden;
//     position:  absolute;
//   }

//   .tabs li:not(.is-active) {
//     -webkit-box-flex: 0;
//     -ms-flex-positive: 0;
//     flex-grow: 0;
//     -ms-flex-negative: 1;
//     flex-shrink: 1;
//   }

//   .tabs .icon {
//     margin-left: 0.5em;
//   }
// }

// .tabs li {
//   transition: flex-grow 1s ease;
// }

@media (max-width: 768px) {
  .c-richtext.content p > img {
    object-fit: contain;
    object-position: top;
    height: auto !important;
  }
}

@media (min-width: 481px) {
  .c-text--desktop {
    display: inline-block;
  }

  .c-text--mobile {
    display: none;
  }

  .tab--container {
    image: {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .c-text--desktop {
    display: none;
  }

  .c-text--mobile {
    display: inline-block;
  }

  .c-richtext.content.right > div p,
  .c-richtext.content.left > div p {
    text-align: center !important;
  }

  .c-richtext.content.description {
    text-align: center !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  footer.footer {
    .section--ListSection {
      .c-richtext.content {
        text-align: center !important;
      }
    }
  }
}

.c-richtext.content.right p {
  text-align: right !important;
}

.c-richtext.content.left p {
  text-align: left !important;
}

.c-opportunity {
  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: center;

    .border {
      width: 6px;
      top: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  &.vertical,
  &.simple {
    .border {
      height: 6px;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  &.simple {
    padding: 0px;

    .fields-container {
      margin: 0px !important;
      padding: 6px 4px;
      color: white;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      div {
        justify-content: center;
        font-weight: bold;
      }
    }

    .image-container {
      margin-top: 6px;
      height: 160px;
      flex-direction: column;
      display: flex;
      justify-content: center;
    }
  }

  &.details {
    border-radius: 0;
    margin: -12px 0px;
    display: flex;
  }

  .c-dropzone {
    display: flex;
  }
}

.column .c-opportunity {
  width: 100%;
}

.column.is-equal-height {
  display: flex;
}

.c-opportunity-details {
  h2.title {
    margin: 0;
  }

  .box {
    box-shadow: 0 2px 15px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }

  .box.header {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .back-button:hover {
    text-decoration: underline;
  }

  .left-border {
    position: absolute;
    width: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    top: 0px;
    left: 0px;
    bottom: 0px;
  }
}

.image-wrapper--top {
  margin-bottom: 16px;
}

.options-container {
  display: flex;
  flex-direction: row;
  gap: 6px;

  .condition {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.item--card {
  height: 100%;
  overflow: hidden;
  .item__image {
    margin: -20px -20px 12px -20px;
  }
}

.item--link {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .c-opportunity-details .box.header {
    display: block;
  }

  .c-opportunity.horizontal {
    display: block;

    .image-container > img {
      margin-bottom: 10px !important;
    }
  }

  .field--details {
    display: block !important;
  }
}

.navbar.secondary {
  position: absolute;
  right: 50px;
  height: 50px !important;
  background-color: transparent;
  border: none;

  a.navbar-item > span {
    color: white;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #bbb;
    background-color: var(--ac-color-primary);
  }

  a.navbar-item.is-active > span {
    filter: brightness(90%);
  }

  a.navbar-item:hover,
  a.navbar-item.is-active {
    background: transparent !important;
  }
}

.field--details {
  display: flex;
  margin-bottom: 8px;
}

.divider--colorized {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

figure > img {
  &.is-rounded-corner {
    border-radius: 8px;
  }
}

.c-richtext a {
  overflow-wrap: anywhere;
}

.card {
  > .card-image {
    & > figure.image > img {
      object-fit: cover;
    }

    & > figure.image > .icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      font-size: 4rem;
    }
  }

  figure {
    &.image.image--color-overlay::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .media-content {
    .subtitle:not(.is-spaced) + p {
      margin-top: -1.25rem;
    }
  }

  > .card-content {
    a {
      word-break: break-all;
    }
  }

  &.card--none {
    background: transparent;
    border: 0;
    box-shadow: none;

    > .card-content {
      padding: 0;
    }
  }
}

.section {
  &.is-full-width {
    padding-left: 0;
    padding-right: 0;

    > .container {
      width: 100%;
      max-width: 100%;
    }
  }

  &.section--no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.header-menu-is-shown {
  position: relative;
  padding-top: 40px;
}

.header-menu-is-shown .navbar-item.menu-settings {
  color: white;
  position: absolute;
  top: 4px;
  right: 0;
  padding: 0;
  font-size: 15px;
}

.header-menu {
  &--social-media {
    font-size: 1.22em;
    margin-right: 10px;
  }

  &--language {
    margin-bottom: 5px;
  }

  &--button {
    font-size: 15px !important;
    margin-left: 4px;
  }
}

.mobile-footer-button {
  display: none;
}

@media (max-width: 480px) {
  .header-menu-is-shown {
    position: unset;
    padding-top: 0px;

    .navbar-item.menu-settings {
      padding: 4px 12px;
      position: relative;
    }
  }

  .header-menu {
    &--social-media {
      font-size: 1.22em;
      margin-right: 10px;
      padding: 0 5px 0 5px;
    }

    &--container {
      display: flex;
      flex-direction: column-reverse !important;
    }

    &--button {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }

  .container--has-footer-button {
    padding-bottom: 61px;
  }

  .mobile-footer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 -5px 5px -5px #333;
  }
}

@media (max-width: 480px) {
  .container--has-footer-button {
    padding-bottom: 61px;
  }
}

.mobile-footer-button {
  display: none;
}

@media (max-width: 480px) {
  .mobile-footer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 -5px 5px -5px #333;
  }
}

.hide-button {
  border: none !important;
  &.hidden {
    color: #db2828;
  }
}

.duplicate-button {
  background: transparent;
  border: none !important;
  color: grey;
  opacity: 0.7;
  transition: opacity 250ms;
  &:hover {
    opacity: 1;
  }
}

.column {
  &.force-flex-grow {
    flex-grow: 1 !important;
  }

  &.column--no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.columns,
.columns.is-gapless {
  > .column {
    &.column--extra-padding {
      padding: 32px 72px !important;

      @media (max-width: 720px) {
        padding: 24px !important;
      }
    }
  }
}

.section--no-vertical-padding {
  .columns,
  .columns.is-gapless {
    > .column:not(.column--extra-padding) {
      padding: 0 !important;
    }
  }
}

.react-parallax > img {
  object-fit: cover;
}

.add-section {
  &.placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-weight: bold;
    flex-direction: column;
    text-align: center;
    line-height: 3;
    font-size: 30px;
  }

  &.button-only {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 80px;
  }
}

.program-wrapper {
  .image-container {
    display: flex;
    flex-direction: column;
  }
}

.meta-data-img-content {
  max-width: 400px;
  max-height: 300px;
}

.react-multiple-carousel__arrow {
  z-index: 15 !important;
}

// PREVIOUS GENERATED STYLE

body {
  background: var(--ac-background-image, var(--ac-color-background));
  background-size: cover;
  min-height: 100vh;
}

.container--left-menu .menu .menu-list a.is-active {
  background-color: var(--ac-color-primary);
  color: var(--ac-color-contrast);
}

.is-topLogo-menu .logo--container {
  background-color: var(--ac-color-primary);
}

.c-searchbar__label {
  color: var(--ac-color-primary);
}

.c-searchbar span.icon.is-left {
  background-color: var(--ac-color-primary);
}

.c-searchbar .control.has-icons-left > input,
.c-searchbar .dropdown-trigger > .button {
  border: 1px solid var(--ac-color-primary);
}

.c-searchbar .dropdown span.icon {
  color: var(--ac-color-primary);
}

.item--link .button {
  background-color: var(--ac-color-primary);
  color: white;
}

.list-section__icon {
  color: var(--ac-color-primary);
}

.list-section__social {
  color: var(--ac-color-primary) !important;
}

.image.image--primary-shadow {
  display: block;
  position: relative;
  box-shadow: -32px 32px var(--ac-color-primary);
  margin-bottom: 32px;
}

.image.image--right.image--primary-shadow {
  box-shadow: 32px 32px var(--ac-color-primary);
}

footer.footer {
  background-color: var(--ac-color-primary);
  padding: 10px;
}

.c-arrow--scroll-to-top {
  background-color: var(--ac-color-primary);
}

form .button {
  background-color: var(--ac-color-primary);
}
.section--InformationSection {
  .c-icon {
    color: var(--ac-icon-color);
    &--square,
    &--circle,
    &--hexa {
      color: var(--ac-icon-accent-color);
      background: var(--ac-icon-bg-color);
      &:before {
        border-bottom-color: var(--ac-icon-bg-color);
      }
      &:after {
        border-top-color: var(--ac-icon-bg-color);
      }
    }
    &-hover:hover &--diamond:before,
    &--diamond:hover:before {
      border-color: var(--ac-icon-color);
    }
    &--bubble {
      border-color: var(--ac-icon-color);
    }
  }
}

.c-item-list__item .c-item-list__hexa {
  background: var(--ac-color-primary);
}

.c-item-list__item .c-item-list__hexa:before {
  border-bottom-color: var(--ac-color-primary);
}
.c-item-list__item .c-item-list__hexa:after {
  border-top-color: var(--ac-color-primary);
}
.c-item-list__item .title {
  background: var(--ac-color-primary);
}

.c-item--partner--card:hover {
  border-color: var(--ac-color-primary);
}

.c-item--partner--card .title {
  background-color: var(--ac-color-primary);
}

.c-opportunity .border,
.c-opportunity .border {
  background-color: var(--ac-color-primary);
}

.c-opportunity.simple .fields-container {
  background-color: var(--ac-color-primary);
}

.c-opportunity:hover {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px var(--ac-color-primary) !important;
}

.c-opportunity div > i,
.c-opportunity__group-label {
  color: var(--ac-color-primary);
}

.c-opportunity-details hr,
.c-opportunity-details .left-border {
  background-color: var(--ac-color-primary);
}

.c-opportunity-details a.back-button {
  color: var(--ac-color-primary);
}

.c-opportunity-details h2.section-title {
  margin-bottom: 10px;
}

.c-opportunity-details h2.section-title > i {
  color: var(--ac-color-primary);
  text-align: left;
  width: auto;
  margin-right: 10px;
}

.tabs li.is-active a {
  border-bottom-color: var(--ac-color-primary);
  color: var(--ac-color-primary);
}

.c-program-table__item__time {
  color: var(--ac-color-primary);
}

.image--color-overlay::after {
  background-color: var(--ac-color-primary-50);
}

.hex p {
  background-color: var(--ac-color-primary-70);
}

.timeline-event:hover .timeline-event-icon {
  background-color: var(--ac-color-primary);
}

.timeline-event:hover .timeline-event-header {
  background-color: var(--ac-color-primary);
}

.workshop--filters .dropdown i,
.workshops .workshop .main.title,
.workshops .workshop {
  .date,
  .subCategory,
  .location {
    color: var(--ac-color-primary);
  }
}

.workshops .workshop .category {
  background-color: var(--ac-color-primary);
}

.workshop--content .header {
  border-bottom-color: var(--ac-color-primary);
}

.workshops .workshop .footer,
.workshops.list .left-section {
  border-top-color: var(--ac-color-primary);
}

.workshop .tag.register {
  background-color: var(--ac-color-primary-20);
  color: var(--ac-color-primary);
  border: 1px solid var(--ac-color-primary);
}

.mobile-footer-button .button {
  background-color: var(--ac-color-primary);
  color: #fff;
}

.section--CountdownSection {
  .countdown {
    .clock {
      background-color: var(--ac-countdown-bg-color);
      color: var(--ac-countdown-color);
    }
  }

  .countdown-item {
    color: var(--ac-countdown-bg-color);
  }

  .section--dark .countdown-item {
    color: var(--ac-countdown-color);
  }

  .countdown-svg path {
    stroke: var(--ac-countdown-bg-color);
  }
}

.section--dark .countdown-svg path {
  stroke: #fff;
}

.Accordion__trigger {
  border-color: var(--ac-color-primary);
}

.Accordion__trigger .info .time {
  color: var(--ac-color-primary);
}

.Accordion__Group {
  background-color: var(--ac-color-primary-50);
}

.Accordion__Group.is-selected {
  background-color: var(--ac-color-primary);
}
