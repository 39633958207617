$side-menu-width: 240px;

main {
  flex-grow: 1;
}
.container--classic {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.container--has-header-menu {
    padding-top: 80px;
  }
}

.container--centered {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media screen and (min-width: 1088px) {
  .container--half-left {
    margin-right: 50%;
    text-align: right;
  }

  .container--half-right {
    margin-left: 50%;
    text-align: left;
  }
}

.is-topLogo-menu {
  position: relative;
  z-index: 200;

  .logo--container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 60px;
    }
  }

  .sticky-menu {
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.navbar {
  &.navbar.is-fixed-top {
    .navbar-brand img {
      max-height: 50px;
    }
  }

  &.invert {
    background-color: white;
  }

  &.is-centered-menu {
    position: fixed;
    width: 100%;
    top: 0;

    .container {
      display: flex;
      min-height: 100px;
      align-items: center;
      justify-content: center;

      .navbar-menu {
        margin-right: 0px;
        flex-grow: unset;

        .navbar-item {
          color: white;
        }
      }

      .navbar-brand {
        margin-left: 20px;
        margin-right: 20px;

        img {
          max-height: 80px;
        }
      }
    }
  }

  h1,
  h2 {
    margin-bottom: 0;
  }

  &-item--flags {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 12px;
    cursor: initial;

    img {
      vertical-align: middle;
      width: 32px;
      height: auto;
      max-height: initial;
      margin-left: 4px;
    }
  }
}

.container--left-menu {
  margin-left: $side-menu-width;

  .navbar {
    display: none;
  }

  .menu {
    background-color: var(--ac-color-white-2);
    color: #f7f7f7;

    .menu-brand {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }

    .menu-subtitle {
      margin-bottom: 20px;
      font-weight: bold;
    }

    &.is-fixed-left {
      position: fixed;
      width: $side-menu-width;
      border-right: 1px solid #ddd;
      left: 0;
      padding: 40px 10px;
      height: 100%;
      border-right: 1px solid #ddd;
    }
  }

  // Fix container width, take into account side menu size
  .container {
    @media screen and (min-width: 1088px) {
      max-width: 960px - $side-menu-width;
      width: 960px - $side-menu-width;
    }

    @media screen and (min-width: 1280px) {
      max-width: 1152px - $side-menu-width;
      width: 1152px - $side-menu-width;
    }

    @media screen and (min-width: 1472px) {
      max-width: 1344px - $side-menu-width;
      width: 1344px - $side-menu-width;
    }
  }
}

.container--topLogo {
  .container .navbar-menu {
    &.h-left {
      justify-content: flex-start !important;
    }

    &.h-center {
      justify-content: center !important;
    }

    &.h-right {
      justify-content: flex-end !important;
    }
  }
}

.navbar-item__language {
  a {
    color: inherit;
    font-weight: normal;
    opacity: 0.7;
  }

  &.is-active {
    a {
      font-weight: bold;
      opacity: 1;
    }
  }

  &:not(:last-child)::after {
    content: ' / ';
  }
}

@media (max-width: 767px) {
  .container--left-menu {
    margin-left: 0;
    // Restore top menu
    padding-top: 50px;

    .navbar {
      display: flex;
    }

    .menu {
      display: none;
    }
  }
}

@media screen and (min-width: 1088px) {
  .navbar-item.is-active.is-hoverable:not(:hover) .navbar-dropdown {
    display: none;
  }
}

@media all and (min-width: 769px) {
  .is-topLogo-menu {
    display: block;
  }
  .container--topLogo,
  .container--centered {
    .navbar.is-fixed-top {
      display: none;
    }
  }
}

@media all and (max-width: 768px) {
  .is-topLogo-menu {
    display: none !important;
  }
  .container--topLogo {
    padding-top: 52px;
  }
}

@media all and (max-width: 480px) {
  .navbar {
    &.is-centered-menu {
      display: none;

      .navbar-link,
      a.navbar-item {
        color: white;
        font-size: 15px;
      }
    }

    &.sticky-menu {
      display: none !important;
    }
  }

  .is-topLogo-menu {
    display: none; /* TODO : handle big image on mobile */
  }

  .container--topLogo {
    padding-top: 50px;

    .navbar.navbar.is-fixed-top .navbar-brand img {
      max-height: 52px;
    }
  }

  .container--centered {
    padding-top: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .container--classic {
    padding-top: 50px;

    &.container--has-header-menu {
      padding-top: 50px;
    }
  }
}
